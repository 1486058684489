// @flow
import * as React from 'react';
import styled from 'styled-components';

import {
  Logo,
  EmployeeWrapper,
  Img,
  Name,
  ContactInfo,
  InfoWrapper,
  Wrapper,
} from './index.styles';

type BaseProps = {
  name: string,
  position: string,
  phone: string,
  email: string,
};

type Props = {
  imgUrl: string,
  node: Function,
  employeeImg: string,
  isPlaceholder: boolean,
} & BaseProps;

export const EmployeeInfo = ({ name, position, phone, email }: BaseProps) => (
  <div>
    <InfoWrapper>
      <Name>{name}</Name>
      <p>{position}</p>
    </InfoWrapper>
    <InfoWrapper>
      {phone && (
        <ContactInfo>
          Tel: <span>{phone}</span>
        </ContactInfo>
      )}
      {email && (
        <ContactInfo>
          Epost: <span>{email}</span>
        </ContactInfo>
      )}
    </InfoWrapper>
  </div>
);

const EmployeeCard = ({
  imgUrl,
  name,
  position,
  phone,
  email,
  node,
  employeeImg,
  isPlaceholder,
}: Props) => (
  <EmployeeWrapper ref={node}>
    <Wrapper>
      <Img
        fluid={employeeImg}
        alt={name}
        position={`center ${isPlaceholder ? '-25%' : 'top'}`}
        isPlaceholder={isPlaceholder}
      />
      {isPlaceholder && <Logo inverted />}
    </Wrapper>

    <EmployeeInfo name={name} position={position} phone={phone} email={email} />
  </EmployeeWrapper>
);

export default EmployeeCard;
