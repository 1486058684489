// @flow
import * as React from 'react';
import ReactDOM from 'react-dom';
import root from 'window-or-global';
import debounce from 'lodash/debounce';
import { TweenLite, Power3 } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { VisibleView, Container } from './styles';

const isElement = (elem: Element | Text | null): boolean =>
  elem instanceof HTMLElement;

// Prevent tree shaking of plugins
const plugins: Array<any> = [ScrollToPlugin];

type ArrowButtonProps = {
  direction: 'LEFT' | 'RIGHT',
  visible?: boolean,
  onClick?: Function,
  arrowFill?: string,
};

type Props = {
  step: number,
  renderNextButton: (props: ArrowButtonProps) => React.Element<*>,
  renderPrevButton: (props: ArrowButtonProps) => React.Element<*>,
  children: React.Node,
  isTouch: boolean,
  throttleTimeout: number,
  childrenCount?: number,
};

type State = {
  sumOfWidths: number,
};

export default class ScrolledList extends React.Component<Props, State> {
  visibleView = React.createRef();
  items: Array<number> = [];
  tween: any = null;

  static defaultProps = {
    renderPrevButton: (props: ArrowButtonProps): React.Element<*> => (
      <div {...props} />
    ),
    renderNextButton: (props: ArrowButtonProps): React.Element<*> => (
      <div {...props} />
    ),
    step: 2,
    isTouch: false,
    throttleTimeout: 150,
  };

  state = {
    sumOfWidths: 0,
  };

  componentDidMount() {
    root.addEventListener('resize', this.handleResize);

    if (this.visibleView.current) {
      this.setState({ sumOfWidths: this.calculateChildrensWidths() });
    }
  }

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const sumOfWidths = this.calculateChildrensWidths();
    if (sumOfWidths === prevState.sumOfWidths) {
      return;
    }
    this.setState({ sumOfWidths });
  };

  componentWillUnmount = () => {
    root.removeEventListener('resize', this.handleResize);

    if (this.tween !== null) {
      this.tween.kill();
      this.tween = null;
    }
  };

  handleResize = debounce(() => this.forceUpdate(), this.props.throttleTimeout);

  previousView = (): void => {
    this.scrollTo(
      this.visibleViewScrollLeft() - this.props.step * this.averageItemWidth()
    );
  };

  nextView = (): void => {
    this.scrollTo(
      this.visibleViewScrollLeft() + this.props.step * this.averageItemWidth()
    );
  };

  scrollTo(offset: number): void {
    if (this.tween !== null && this.tween.isActive()) {
      this.tween.invalidate();
    }

    const roundedOffset = Math.round(offset);

    let scrollLeft =
      roundedOffset > this.visibleViewMaxWidth()
        ? this.visibleViewMaxWidth()
        : roundedOffset;

    scrollLeft = scrollLeft < 0 ? 0 : scrollLeft;

    if (scrollLeft === this.visibleViewScrollLeft()) {
      return;
    }

    this.tween = TweenLite.to(this.visibleView.current, 1, {
      scrollTo: { x: scrollLeft, autoKill: false },
      ease: Power3.easeOut,
      force3D: true,
      onUpdate: () => this.forceUpdate(),
    });
  }

  visibleViewScrollLeft(): number {
    return this.visibleView.current ? this.visibleView.current.scrollLeft : 0;
  }

  visibleViewWidth(): number {
    return this.visibleView.current
      ? this.visibleView.current.getBoundingClientRect().width
      : 0;
  }

  visibleViewMaxWidth(): number {
    return Math.floor(this.state.sumOfWidths - this.visibleViewWidth());
  }

  averageItemWidth(): number {
    const count = this.props.childrenCount
      ? this.props.childrenCount
      : React.Children.count(this.props.children);

    return this.state.sumOfWidths / count;
  }

  showPrevButton(): boolean {
    return this.visibleViewScrollLeft() > 0;
  }

  showNextButton(): boolean {
    return this.visibleViewScrollLeft() < this.visibleViewMaxWidth();
  }

  calculateChildrensWidths(): number {
    const node = ReactDOM.findDOMNode(this.visibleView.current);

    if (isElement(node)) {
      // $FlowFixMe
      const children = Array.from(node.children);

      return children.reduce((sum, child) => {
        const width = child.getBoundingClientRect().width;
        return sum + width;
      }, 0);
    }

    return 0;
  }

  render() {
    const {
      renderNextButton,
      renderPrevButton,
      children,
      isTouch,
    } = this.props;
    const showArrows: boolean = !isTouch;

    return (
      <Container>
        {showArrows && this.showPrevButton()
          ? renderPrevButton({
              onClick: this.previousView,
              direction: 'LEFT',
            })
          : null}
        <VisibleView id="visibleView" ref={this.visibleView} isTouch={isTouch}>
          {children}
        </VisibleView>
        {showArrows && this.showNextButton()
          ? renderNextButton({
              onClick: this.nextView,
              direction: 'RIGHT',
            })
          : null}
      </Container>
    );
  }
}
