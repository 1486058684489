// @flow
import styled from 'styled-components';
import { media } from '../../utils/style-utils';
import { GatsbyImage } from '../Styled';
import { Logo as LogoImg } from '../Navigation/styles';

export const EmployeeWrapper = styled.div`
  position: relative;
  padding-right: 50px;
  min-width: 265px;
  max-width: 265px;

  ${media.desktop`
    min-width: 290px;
    max-width: 290px;
  `};
`;

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const Img = styled(GatsbyImage)`
  position: relative !important;
  width: 100%;
  height: 300px;
  background: linear-gradient(
    to right,
    rgba(88, 87, 84, 0.2),
    rgba(246, 240, 221, 0.2)
  );
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);

  ${({ isPlaceholder }) =>
    isPlaceholder &&
    `
    opacity: 0.15;
  `};
`;

export const Name = styled.p`
  font-weight: 500;
  font-size: 18px;

  ${media.desktop`
    font-size: 20px;
  `};
`;

export const ContactInfo = styled.p`
  font-size: 14px;
  font-weight: 400;

  & span {
    font-weight: 500;
  }
`;

export const InfoWrapper = styled.div`
  margin: 16px 0;
`;

export const Logo = styled(LogoImg)`
  position: absolute;

  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
`;
