import styled from 'styled-components';
import { FlexDiv } from '../Styled';

export const VisibleView = styled(FlexDiv)`
  height: 100%;
  overflow-x: ${({ isTouch }) => (isTouch ? 'scroll' : 'hidden')};
  -webkit-overflow-scrolling: ${({ isTouch }) => (isTouch ? 'touch' : 'auto')};
  z-index: 0;
  padding: 3rem 3rem 3rem 0;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Container = styled(FlexDiv)`
  height: 100%;
  position: relative;
`;
