// @flow
import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { navConfig } from '../Navigation';
import { SubHeader } from '../Styled';
import { openPositions } from './openPositions';

const List = styled.ul`
  display: flex;
  align-items: center;
`;
const ListItem = styled.li`
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
`;

const ListStyle = styled.div`
  height: 2px;
  width: 24px;
  background: linear-gradient(to right, #302e2a, #3a3732);
  margin-right: 12px;
`;

type Props = {
  colors: {
    colorFrom: string,
    colorTo: string,
  },
  isIE: boolean,
};

const JobOpenings = ({ colors, isIE }: Props) => (
  <React.Fragment>
    <SubHeader {...colors} isIE={isIE}>
      Lediga tjänster
    </SubHeader>
    <List>
      {openPositions.map(position => (
        <React.Fragment key={position.title}>
          <ListStyle />
          <Link to={navConfig.position.url} id="test-id-jobs-link">
            <ListItem>{position.title}</ListItem>
          </Link>
        </React.Fragment>
      ))}
    </List>
  </React.Fragment>
);

export default JobOpenings;
